import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import * as serviceWorker from './serviceWorker';
import { getFontFaces } from './fonts';

// eslint-disable-next-line
const initReadium = () => {

  let path = (window.location && window.location.pathname) ? window.location.pathname : '';
  path = path.replace(/(.*)\/.*\.[x]?html$/ui, '$1');

  path = path.charAt(path.length - 1) === '/'
    ? path.substr(0, path.length - 1)
    : path;

  const HTTPServerRootFolder = window.location ? (
    `${window.location.protocol
    }//${
      window.location.hostname
    }${window.location.port ? (`:${window.location.port}`) : ''
    }${path}`
  ) : '';

  const getURLQueryParams = function () {
    const params = {};

    let query = window.location.search;
    if (query && query.length) {
      query = query.substring(1);
      const keyParams = query.split('&');
      for (let x = 0; x < keyParams.length; x += 1) {
        const keyVal = keyParams[x].split('=');
        if (keyVal.length > 1) {
          params[keyVal[0]] = decodeURIComponent(keyVal[1]);
        }
      }
    }

    return params;
  };

  const urlParams = getURLQueryParams();
  console.log(urlParams);

  let fontsArray = [];
  if (typeof getFontFaces !== 'undefined') { // defined externally
    fontsArray = getFontFaces(`${HTTPServerRootFolder}/font-faces/`);
  }

  // MUST BE *SINGLE* CALL TO require.config() FOR ALMOND (SINGLE BUNDLE) TO WORK CORRECTLY!!!
  require.config({
    /* http://requirejs.org/docs/api.html#config-waitSeconds */
    waitSeconds: 0,

    config: {

      'readium_js_viewer/ModuleConfig': {

        mathJaxUrl: `${HTTPServerRootFolder}/scripts/mathjax/MathJax.js`,

        fonts: fontsArray,

        annotationCSSUrl: `${HTTPServerRootFolder}/css/annotations.css`,

        jsLibRoot: `${HTTPServerRootFolder}/scripts/zip/`,

        useSimpleLoader: false, // cloud reader (strictly-speaking, this config option is false by default, but we prefer to have it explicitly set here).

        epubLibraryPath: urlParams.epubs ? urlParams.epubs : 'epub_content/epub_library.opds', // defaults to /epub_content/epub_library.json relative to the application's root index.html

        imagePathPrefix: undefined,

        canHandleUrl: false,
        canHandleDirectory: false,


        workerUrl: undefined,
        epubReadingSystemUrl: undefined,
      },
    },
  });
};

/*
initReadium();
*/

ReactDOM.render(<App />, document.getElementById('app-container'));
// ReactDOM.render(<Editor socket={socket} />, document.getElementById('editor'));
// ReactDOM.render(<Opds2 />, document.getElementById('opds2'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
