import React from 'react';
import './PublicationCard.css';
import { blue } from '@ant-design/colors';
import { Icon } from 'antd';

export const PublicationCard = ({ onClick, isActive, publication: { links, images, metadata } }) => {
  console.log(metadata)
  const { creator, name, id } = metadata
  const pubHref = links[0].href;
  const imgSrc = (images || []).map(({ href }) => href).concat(['/images/default_cover_01.jpg'])[0];
  return (
    <div
      className="publication-card"
      key={id}
      onClick={() => onClick(pubHref)}
    >
      <div className="cover">
        {imgSrc ? (<img src={imgSrc} alt="Обложка"/>) : (<Icon type="book"/>)}
      </div>
      <div className="info">
        <h2>
          {creator}
        </h2>
        <h1 style={{ color: isActive ? blue.primary : '#666666' }}>
          {name}
        </h1>
        <div className="identifier">{id}</div>
      </div>
    </div>
  );
};
