import React from 'react';

import { Button, ButtonGroup, Col, Icon, notification, Popconfirm, Row, Spin } from 'antd';
import { red } from '@ant-design/colors';
import { green } from '@ant-design/colors/lib';
import { PublicationMetadata } from './PublicationMetadata';
import { ControlledEditor } from '@monaco-editor/react';
import 'antd/dist/antd.css';

import i18n from './i18n';
import { Publications } from './Publications';
import { TocTree } from './Toc';
import { UploadInput } from './Upload';
import './App.css';

const EDITOR_OPTIONS = {
  selectOnLineNumbers: true,
  renderControlCharacters: true,
  cursorStyle: 'line',
  theme: 'vs-light',
  wordWrap: true,
  automaticLayout: true,
  scrollBeyondLastLine: false,
};
const t = i18n.t;

const apiEndpoint = (window && window.location && window.location.origin ? window.location.origin : '//localhost') + '/opds2';

const assignKeys = (nodes) => Array.isArray(nodes) ? nodes.map(node => {
  const key = node.url || node.name || node.title;
  return ({
    ...node,
    key,
    children: assignKeys(node.children),
  });
}) : nodes;

export class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      publications: null,
      html: '',
      sectionUrl: null,
      openedPublicationObject: null,
      isLoading: true,
    };
    notification.config({
      placement: 'topRight',
      top: 32,
      right: 0,
      duration: 5,
    });
  }


  componentDidMount() {
    this.apiGetPublications().catch(
      e => {
        notification.error({ message: `${e.message}\n${e.stack}` });
        throw e;
      },
    ).then();
  }

  async apiGetPublications() {
    this.setState({ isLoading: true });
    try {
      const publications = await fetch(apiEndpoint + '/');
      const res = await publications.json();
      if (res && res.error) {
        notification.error({ message: typeof res.error === 'string' ? res.error : res.error.message });
        this.setState({
          publications: [],
          isLoading: false,
        });
      } else {
        this.setState({
          publications: (res.groups || []).reduce((acc, { publications }) => ([...acc, ...publications]), []),
          isLoading: false,
        });
      }
    } catch (e) {
      notification.error({ message: e.message || e });
      this.setState({
        publications: [],
        isLoading: false,
      });
    }
  };

  async apiGetPublication(publicationHref) {
    if (!publicationHref) {
      notification.error({ message: t(`No manifest URL is defined for publication with ID=${publicationHref}`) });
    } else {
      this.setState({
        isLoading: true,
      });
      try {
        const res = await fetch(publicationHref);
        const openedPublicationObject = await res.json();
        this.setState({
          openedPublicationObject: { ...openedPublicationObject, ...(openedPublicationObject.metadata || {}) },
          isLoading: false,
        });
      } catch (e) {
        notification.error({ message: e.message || e });
        this.setState({
          isLoading: false,
        });
      }
    }
  };

  async apiUpdatePublicationResource({ resourceUrl, newData }) {
    if (!resourceUrl) {
      notification.error({ message: t('No remote resource URL was defined') });
      return newData;
    } else {
      this.setState({
        isLoading: true,
      });
      const res = await fetch(
        resourceUrl,
        {
          body: typeof newData === 'string' ? newData : JSON.stringify(newData),
          method: 'PATCH',
          mode: 'cors', // no-cors, *cors, same-origin
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          credentials: 'same-origin', // include, *same-origin, omit
          headers: {
            'Content-Type': 'text/xml',
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          redirect: 'follow', // manual, *follow, error
          referrerPolicy: 'no-referrer', // no-referrer, *client
        },
      );
      // Retrieve result
      const resourceText = await res.text();
      notification.info({
        message: t(`Resource with URL ${resourceUrl} was successfully updated`),
      });
      this.setState({
        isLoading: false,
      });
      return resourceText;
    }
  }

  async apiUpdatePublication(publicationHref, newPublication) {
    if (!publicationHref) {
      notification.error({ message: t(`No manifest URL is defined for publication with ID=${publicationHref}`) });
    } else {
      this.setState({
        isLoading: true,
      });
      // try {
      const res = await fetch(
        publicationHref,
        {
          body: JSON.stringify(newPublication),
          method: 'PATCH',
          mode: 'cors', // no-cors, *cors, same-origin
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          credentials: 'same-origin', // include, *same-origin, omit
          headers: {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          redirect: 'follow', // manual, *follow, error
          referrerPolicy: 'no-referrer', // no-referrer, *client
        },
      );
      // Retrieve result
      const openedPublicationObject = await res.json();
      if (openedPublicationObject.error) {
        notification.error({ message: openedPublicationObject.error });
        this.setState({
          isLoading: false,
        });
      } else {
        this.setState({
          openedPublicationObject: { ...openedPublicationObject, ...(openedPublicationObject.metadata || {}) },
          html: '',
          changedHtml: '',
          isLoading: false,
        });
        notification.info({
          message: t(`Publication updated`),
          description: `ID=${openedPublicationObject.id || openedPublicationObject.metadata.id}`,
        });
      }
    }
  }

  // noinspection JSUnusedGlobalSymbols
  async apiImport(req) {
    this.setState({ isLoading: true });
    const formData = new FormData();

    formData.append('username', req.name);
    formData.append('avatar', req.blob);

    const importResult = await fetch(apiEndpoint, {
      method: 'PUT',
      body: req.blob,
    });
    const result = await importResult.json();
    if (result.error) {
      notification.error({
        message: [
          t('Import of Publication with identifier:'),
          result.metadata.id,
          t('have been failed with error:'),
          result.error.message || result.error,
        ].join(' '),
      });
      this.setState({
        error: result.error,
        isLoading: false,
      });
    } else {
      notification.info({
        message: [
          t('Import of Publication with identifier:'),
          result.metadata.id,
          t('is complete'),
        ],
      });
      return this.apiGetPublications().catch(
        e => notification.error({ message: e.message || e }),
      ).finally(
        () => this.setState({
          isLoading: false,
        }),
      );
    }
  };

  async apiDelete(publicationUrl) {
    if (!publicationUrl) {
      return { error: t('No publication URI defined') };
    }
    const result = await fetch(publicationUrl, { method: 'DELETE' });
    const resultJson = await result.json();
    if (resultJson.error) {
      throw new Error([
        t('Publication with identifier:'),
        publicationUrl,
        t('have NOT been deleted due following server-side error:'),
        resultJson.error.message || resultJson.error,
      ].join(' '));
    } else {
      notification.info({
        message: [
          t('Publication with identifier:'),
          publicationUrl,
          t('have been successfully deleted'),
        ].join(' '),
      });
      await this.apiGetPublication(publicationUrl);
    }
    return resultJson;
  };


  async apiGetSection(sectionUrl) {
    this.setState({ isLoading: true });
    try {
      const res = await fetch(sectionUrl, { method: 'GET' });
      this.setState({
        isLoading: false,
        sectionUrl,
      });
      return await res.text();
    } catch (e) {
      notification.error({ message: e.message });
      this.setState({ isLoading: false });
      throw e;
    }
  };

  // noinspection JSUnusedLocalSymbols
  async apiUpload({ publicationUrl, contentType, bloc }) {
    this.setState({ isLoading: true });
    notification.warning({
      message: ['apiUpload', t('interface module is not implemented')].join(' '),
    });
    this.setState({ isLoading: false });
  }

  onPublicationSelect(publicationUrl) {
    this.setState({
      publicationUrl,
      sectionUrl: null,
      isLoading: true,
      html: '',
      changedHtml: '',
    });
    this.apiGetPublication(publicationUrl).then(
      () => this.setState({
        isLoading: false,
        sectionUrl: null,
      }),
    );
  }

  render() {
    const {
      publications,
      publicationUrl,
      sectionUrl,
      openedPublicationObject,
      changedHtml,
      html,
      isLoading,
    } = this.state;
    const downloadUrl = openedPublicationObject && publicationUrl
      ? publicationUrl.replace(
        /\/(manifest.json)?$/ui,
        '/.build/' + openedPublicationObject.id.split(':').slice(-1)[0] + '.epub',
      )
      : null;
    let coverImg = openedPublicationObject ? (openedPublicationObject.resources || []).filter(
      ({ rel }) => (Array.isArray(rel) ? rel.join(' ') : rel || '').match(/^cover/ui),
    ).map(
      ({ url }) => url,
    )[0] : null;
    coverImg = coverImg ? (publicationUrl || '').replace(/[^/]+\.[^/]+/, coverImg) : null;
    return (<Row
      type="flex"
      justify="start"
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <Col span={6}>
        <Spin
          spinning={isLoading}
          wrapperClassName="spin-full-size"
          className="spin-full-size"
          tip="Wait a little"
        >
          {publications
            ? (<Publications
              publications={publications}
              apiEndpoint={apiEndpoint}
              url={publicationUrl}
              onUpload={async () => {
                this.apiGetPublications().catch(
                  e => notification.error({ message: (<div>{e.message}<br/>{e.stack}</div>) }),
                ).finally(() => this.setState({ isLoading: false }));
              }}
              onClick={(publicationUrl) => this.onPublicationSelect(publicationUrl)}
              publicationUrl={publicationUrl}
              isLoading={isLoading}
            />) : null
          }
        </Spin>
      </Col>
      <Col span={18}>
        {openedPublicationObject ? (<Row type="flex" className="publication-title">
          <Col span={4}>
            <Button.Group>
              <Button
                target="_blank"
                title={t('Download publication')}
                icon="cloud-download"
                href={downloadUrl}
                style={{ color: green.primary }}
              />
              <Button
                icon="save"
                title={t('Save')}
                onClick={() => (changedHtml !== html) ? this.apiUpdatePublicationResource({
                  resourceUrl: sectionUrl,
                  newData: changedHtml,
                }).then(() => {
                  this.setState({
                    html: changedHtml,
                    changedHtml,
                    isLoading: false,
                  });
                }) : null}
                disabled={(changedHtml || '').replace(/(&nbsp;|[\p{Zs}]+)/uig, ' ') === (html || '').replace(/(&nbsp;|[\p{Zs}]+)/uig, ' ')}
              />
            </Button.Group>
          </Col>
          <Col className="publication-title-text" span={18}>
            <h2>{openedPublicationObject.creator}</h2>
            <h1>
              {openedPublicationObject.title}
            </h1>
            <div className="identifier">
              {openedPublicationObject.id} Версия
              от:{' '}{new Date(openedPublicationObject.dateModified).toLocaleString()}
            </div>
          </Col>
          <Col className="publication-actions" span={2}>
            <Popconfirm
              placement="bottom"
              title={t('You\'re about to DELETE publication, are you sure about this?')}
              onConfirm={(e) => {
                e.stopPropagation();
                this.setState({
                  sectionUrl: null,
                  isLoading: true,
                });
                this.apiDelete(publicationUrl).catch(
                  e => notification.error({ message: e.message || e }),
                ).then(
                  () => this.setState({
                    isLoading: false,
                    sectionUrl: null,
                    html: '',
                    changedHtml: '',
                  }),
                );
              }}
              okText={<span>{t('YES, delete')}</span>}
              cancelText={<span>{t('NO, don\'t delete')}</span>}
              okButtonProps={{ type: 'danger' }}
              icon={<Icon type="question" style={{ color: red.primary }}/>}
            >
              <Button style={{
                float: 'right',
                color: red.primary,
              }}>
                <Icon type="delete" title={t('Delete publication')}/>
              </Button>
            </Popconfirm>
          </Col>
        </Row>) : null}
        {openedPublicationObject ? (
          <Row style={{
            height: '100%',
            overflow: 'hidden',
          }}>
            <Col span={12} style={{
              overflowY: 'auto',
              height: '100%',
            }}>
              <UploadInput
                key={coverImg}
                imageUrl={coverImg}
                onLoad={
                  async (blob) => new Promise((resolve, reject) => {
                    this.setState({ isLoading: true });
                    this.apiUpload({
                      publicationUrl,
                      contentType: 'image/jpeg',
                      blob,
                    }).catch(
                      (e) => {
                        this.setState({ isLoading: false });
                        reject(e);
                      },
                    ).then(
                      () => this.apiGetPublications().catch(reject).then(resolve),
                    );
                  })
                }
                text={t('Загрузка файла обложки')}
              />
              <TocTree
                onTocChange={
                  async (toc) => this.apiUpdatePublication(
                    publicationUrl,
                    {
                      ...openedPublicationObject,
                      toc: [...toc],
                    },
                  )
                }
                data={assignKeys(openedPublicationObject.toc || {})}
                url={publicationUrl}
                onSelect={
                  async (keys, event) => {
                    if (keys.length > 0) {
                      const sUrl = (keys.length > 0) ? (publicationUrl.replace(/\/manifest.json/uig, '/')) + (keys[0].replace(/#.*$/ui, '')) : null;
                      const sectionHtml = await this.apiGetSection(sUrl);
                      const html = (sectionHtml || '').replace(/(&nbsp;|[\p{Zs}]+)/uig, ' ');
                      this.setState({
                        html: html || '',
                        changedHtml: html || '',
                        sectionUrl: sUrl,
                      });
                    } else {
                      this.setState({
                        html: '',
                        changedHtml: '',
                        sectionUrl: null,
                      });
                    }
                  }
                }
              />
            </Col>
            <Col span={12} style={{
              overflow: 'hidden',
              maxHeight: '100%',
            }}>
              {sectionUrl ? (<ControlledEditor
                  options={EDITOR_OPTIONS}
                  theme={'dark'}
                  language={'html'}
                  value={this.state.changedHtml}
                  onChange={(changes, changedHtml) => {
                    this.setState({ changedHtml });
                  }}
                  loading={'Loading...'}
                />)
                : (<PublicationMetadata
                  publication={openedPublicationObject}
                  url={publicationUrl}
                />)}
            </Col>
          </Row>) : null}
      </Col>
    </Row>);
  }
}

/*
{sectionUrl ? (<CodeEditor
                  onChange={(value) => {
                    this.setState({
                      html,
                      changedHtml: value,
                    });
                  }}
                  onInit={
                    (setValue) => {
                      this.setValue = setValue || this.setValue;
                      this.setValue(html || '');
                    }
                  }
                />)
 */
/*

 */
export default App;
/*
<Button
  title={t('Edit metadata')}
  size="large"
  onClick={
    () => this.setState({ profileEditorOpened: true })
  }
>
  <Icon type="edit"/>
</Button>
*/
