import React from 'react';
import { PublicationCard } from './PublicationCard';
import { UploadInput } from './Upload';
import './Publications.css';

export const Publications = ({ apiEndpoint, publicationUrl, publications, onUpload, onDelete, onClick, isLoading }) => {
  return (
    <div className="publications">
      <div className="publication-card">
        <UploadInput
          allowedFileTypes={[
            'application/epub+zip', 'application/epub', 'application/epub3+zip', 'application/epub3',
            'application/pdf',
            'text/vnd.hocr+html',
            'text/vnd.hocr',
            'text/hocr',
            'application/hocr',
            'application/vnd.hocr',
            'application/xml',
            'text/xml',
          ]}
          onUpload={onUpload}
          apiEndpoint={apiEndpoint}
          text="Импортировать EPUB3/PDF"
        />
      </div>
      {
        (publications || []).sort(
          (a, b) => (
            a.metadata.name || ''
          ).localeCompare(
            b.metadata.name || '',
          ),
        ).map(
          (publication) => (<PublicationCard
            key={`${publication.metadata.id}-${publication.links[0].href}`}
            onClick={onClick}
            isActive={publication.links[0].href === publicationUrl}
            isLoading={isLoading}
            publication={publication}
          />),
        )
      }</div>
  );
};
