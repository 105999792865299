import React from 'react';
import cloneDeep from 'lodash.clonedeep'
import Icon from 'antd/es/icon';
import { Tree } from 'antd';
import './Toc.css';
const moveNode = (tree, info) => {
  const dropKey = info.node.props.eventKey;
  const dragKey = info.dragNode.props.eventKey;
  const dropPos = info.node.props.pos.split('-');
  const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

  const loop = (data, key, callback) => {
    data.forEach((item, index, arr) => {
      if (item.key === key) {
        return callback(item, index, arr);
      }
      if (item.children) {
        return loop(item.children, key, callback);
      }
    });
  };
  const data = cloneDeep(tree);

  // Find dragObject
  let dragObj;
  loop(data, dragKey, (item, index, arr) => {
    arr.splice(index, 1);
    dragObj = item;
  });

  if (!info.dropToGap) {
    // Drop on the content
    loop(data, dropKey, item => {
      item.children = item.children || [];
      // where to insert
      item.children.push(dragObj);
    });
  } else if (
    ((info.node.props.children ? info.node.props.children.size : 0) > 0) && // Has children
    (info.node.props.expanded === true) && // Is expanded
    (dropPosition === 1) // On the bottom gap
  ) {
    loop(data, dropKey, item => {
      item.children = item.children || [];
      // where to insert
      item.children.unshift(dragObj);
    });
  } else {
    let ar;
    let i;
    loop(data, dropKey, (item, index, arr) => {
      ar = arr;
      i = index;
    });
    if (dropPosition === -1) {
      ar.splice(i, 0, dragObj);
    } else {
      ar.splice(i + 1, 0, dragObj);
    }
  }
  return data;
};

export class TocTree extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isDragging: false,
      // expandedKeys: [],
    };
  }

  onDragStart = () => {
    this.setState({ isDragging: true });
  };
  onDragEnter = info => {
    console.info('DragEnter', info);
    // this.setState({
    //   expandedKeys: info.expandedKeys,
    // });
  };

  onDrop = async info => {
    this.setState({ isDragging: false });
    await this.props.onTocChange(moveNode(this.props.data, info));
  };

  render() {
    const loop = (root, semanticStack) => root.map(
      item => {
        const title = item.title || item.name;
        semanticStack = semanticStack || item['epub:type'];

        if (item.children && (item.children.length > 0)) {
          return (<Tree.TreeNode
            key={item.key}
            title={title}
          >
            {loop(item.children, semanticStack)}
          </Tree.TreeNode>);
        } else {
          return <Tree.TreeNode
            key={item.key}
            title={title}
            className={semanticStack}
          />;
        }
      },
    );
    return (<Tree
      className="draggable-tree"
      // defaultExpandedKeys={this.state.expandedKeys}
      showIcon={true}
      switcherIcon={<Icon type="down"/>}
      icon={
        ({ children }) => children && (children.size > 0)
          // ? <Icon type="folder"/>
          ? <Icon type="copy"/>
          : <Icon type="file-text"/>
      }
      onSelect={(nodeUrls) => {
        if (!this.state.isDragging) {
          this.props.onSelect(nodeUrls);
        }
      }}
      draggable
      blockNode
      defaultExpandAll
      onDragStart={this.onDragStart}
      onDragEnter={this.onDragEnter}
      onDrop={this.onDrop}
    >
      {loop(this.props.data)}
    </Tree>);
  }
}

// {/*<Tree.TreeNode*/}
// {/*  key="root"*/}
// {/*  icon={<Icon type="read"/>}*/}
// {/*  title={this.props.data.get('title') || this.props.data.get('title') || '/'}*/}
// {/*>*/}
// {/*</Tree.TreeNode>*/}
