/*
import i18next from 'i18next';
i18next.init(
  {
    interpolation: {
      // React already does escaping
      escapeValue: false,
    },
    debug: true,
    lng: 'en', // 'en' | 'es'
    // Using simple hardcoded resources for simple example
    resources: {
      en: {
        translation: {
          'Given file type is not allowed': 'Given file type is not allowed',
        },
      },
      ru: {
        translation: {
          'Given file type is not allowed': 'Такой тип файла не подходит',
        },
      },
    },
  },
);

export default i18next;
*/
export default { t: (v) => v };
