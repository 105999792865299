import * as React from 'react';
import './Upload.css';
import { Icon, message, Upload } from 'antd';
import i18n from './i18n';

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function getBlob(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsArrayBuffer(img);
}

function beforeUpload(file) {
  // if (!isJpgOrPng) {
  //   message.error('You can only upload JPG/PNG file!');
  // }
  const isHuge = file.size > 1024 * 1024 * 1024 * 2;
  if (isHuge) {
    message.error(`${i18n.t('File must smaller than')} 2GB!`);
  }
  return !isHuge;
}

const IMAGE_FILE_TYPES = ['image/jpeg', 'image/png', 'image/gif', 'image/jp2', 'image/tiff'];
const isImage = (fileType) => IMAGE_FILE_TYPES.indexOf(fileType) !== -1;

export class UploadInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }


  handleChange = (info) => {
    const allowedFileTypes = this.props.allowedFileTypes || IMAGE_FILE_TYPES;
    if (((allowedFileTypes).indexOf(info.file.type) === -1) && (!info.file.name.match(/\.hocr$/uig))) {
      message.error(`${i18n.t('Given file type is not allowed')}: ${info.file.type}, allowed: ${allowedFileTypes.join(', ')}`);
      return;
    }
    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      return;
    }
    const { onUpload } = this.props;
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      const onDone = (blob, imageUrl) => (onUpload || Promise.resolve)(blob).then(() => {
        this.setState({
          imageUrl,
          loading: false,
        });
      });
      if (isImage(info.file.type)) {
        getBase64(
          info.file.originFileObj,
          (imageUrl) => {
            getBlob(
              info.file.originFileObj,
              (blob) => onDone(blob, imageUrl),
            );
          },
        );
      } else {
        getBlob(
          info.file.originFileObj,
          (blob) => onDone(blob),
        );
      }
    }
  };

  render() {
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? 'loading' : 'import'}/>
        <span className="ant-upload-text">{this.props.text}</span>
      </div>
    );
    const imageUrl = this.state.imageUrl || this.props.imageUrl;
    return (
      <Upload
        name="data"
        listType="picture-card"
        className="uploader"
        showUploadList={false}
        multiple={true}
        imageUrl={imageUrl}
        method="POST"
        action={this.props.apiEndpoint}
        beforeUpload={beforeUpload}
        onChange={this.handleChange}
      >
        {imageUrl ? <img src={imageUrl} alt="uploaded"/> : uploadButton}
      </Upload>
    );
  }
}
