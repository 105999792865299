import React from 'react';
import './Publication.css';

// eslint-disable-next-line react/prefer-stateless-function
export class PublicationMetadata extends React.Component {
  render() {
    const { publication } = this.props;
    if (!publication) {
      return (<div>No .metadata is defined</div>);
    }
    return (<table className="toc-metadata">
      <tbody>
      {
        Object.keys(publication).filter(
          (key) => (['readingOrder', 'resources', 'toc'].indexOf(key) === -1),
        ).map(
          (k) => (
            <tr key={k}>
              <td>
                {k}
                :
              </td>
              <td style={{ fontWeight: 'bold' }}>
                {
                  // eslint-disable-next-line no-nested-ternary
                  (typeof publication[k] === 'string')
                    ? publication[k]
                    : (
                      Array.isArray(publication[k]) ? publication[k].map(
                        (el) => ((['string', 'number'].indexOf(typeof el) !== -1) ? el : JSON.stringify(el)),
                      ) : JSON.stringify(publication[k])
                    )
                }
              </td>
            </tr>
          ),
        )
      }
      </tbody>
    </table>);
  }
}
